<template>
  <div class="notfound-page">
    <img src="@/assets/images/404.jpg" alt="找不到页面" />
    <p class="sorry">咦~页面不见了~</p>
  </div>
</template>

<script lang="ts" setup></script>

<style lang="scss" scoped>
.notfound-page {
  height: 100vh;
  width: 100vw;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  img {
    display: block;
    height: 400px;
    margin: 0 auto 50px;
  }

  .sorry {
    font-size: 45px;
    line-height: 68px;
  }
}
</style>
